<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="订单编号">
                <a-input v-model="queryParam['%serial_num']"/>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <!-- <a-button type="danger" @click="handleDelete('all')">删除</a-button> -->
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="(r) => {return r.order.id}"
        :filterColumn="true"
        :columns="columns"
        :data="loadData"
        :scroll="{ x: 'max-content' }"
        @dblclick="handleEdit">
        <!-- :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }" -->
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleCopy(record)">复制</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
          </template>
        </span>
      </s-table>
    </a-card>
  </keep-alive>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import { getItmsOrderPage, deleteItmsOrderInfo } from '@/api/itms'
export default {
  components: {
    STable
  },
  data() {
    return {
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'order.serial_num'
        },
        {
          title: '报价编号',
          dataIndex: 'order.quota_num'
        },
        {
          title: '委托单位',
          dataIndex: 'order.agency_name'
        },
        {
          title: '客户业务编号',
          dataIndex: 'order.customer_num'
        },
        {
          title: '创建时间',
          dataIndex: 'content.created_at',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      currentData: null,
      loadData: parameter => {
        return getItmsOrderPage('list_railway', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      }
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'Railway' && this.queryParam) {
        newRoute.meta.title = '铁路运输管理'
        if (newRoute.query.isEdit && newRoute.params) {
          Object.assign(this.currentData, newRoute.params)
        } else {
          this.$nextTick(_ => {
            this.$refs.table.refresh()
          })
        }
      }
    }
  },
  created() {
    // getCommonOptions('customer').then(v => {
    //   this.customerOps = v
    // })
  },
  methods: {
    moment,
    handleCreate() {
      this.$router.push({
        name: 'RailwayCUR',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record) {
      this.currentData = record
      this.$router.push({
        name: 'RailwayCUR',
        params: {
          id: record.order.id,
          ...record
        }
      })
    },
    handleCopy(record) { // copy
      this.$router.push({
        name: 'RailwayCUR',
        params: {
          id: record.order.id
        },
        query: {
          isCopy: true
        }
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          deleteItmsOrderInfo(record.order).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
