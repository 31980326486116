import { axios } from '@/utils/request'

export function getItmsOrderPage (type, parameter) {
  return axios({
    url: `itms/order/${type}`,
    method: 'get',
    params: parameter
  })
}

// 国贸-订单管理-海运空运分页查询
export function pageListMain(data) {
  return axios({
    url: `itms/order/pageListMain`,
    method: 'post',
    data
  })
}

export function getItmsMetaOption(type, parameter) {
  return axios({
    url: `itms/meta/${type}/option`,
    method: 'get',
    params: parameter
  })
}

export function getItmsMetaPage(type, parameter) {
  return axios({
    url: `itms/meta/${type}/list`,
    method: 'get',
    params: parameter
  })
}

export function createItmsMetaInfo(type, data) {
    return axios({
      url: `itms/meta/${type}/create`,
      method: 'post',
      data
    })
}

// export function saveItmsMetaInfo(type, data) {
//   return axios({
//     url: `itms/meta/${type}/save`,
//     method: 'post',
//     data
//   })
// }

export function updateItmsMetaInfo(type, data) {
    return axios({
        url: `itms/meta/${type}/update`,
        method: 'post',
        data
    })
}

export function deleteItmsMetaInfo(type, data) {
  return axios({
      url: `itms/meta/${type}/delete`,
      method: 'post',
      data
  })
}

export function createItmsOrderInfo(type, data) {
  return axios({
    url: `itms/order/${type}`,
    method: 'post',
    data
  })
}

export function getItmsOrderQuotaNum(params) {
  return axios({
    url: `itms/order/query_quota_num`,
    method: 'get',
    params: params
  })
}

export function deleteItmsOrderInfo(data) {
  return axios({
    url: `itms/order/remove`,
    method: 'post',
    data
  })
}

export function getItmsOrderDetail(parameter) {
  return axios({
    url: `itms/order/detail`,
    method: 'get',
    params: parameter
  })
}

export function getItmsOrderCopyInfo(parameter) {
  return axios({
    url: `itms/order/duplicate`,
    method: 'get',
    params: parameter
  })
}

export function getItmsFinancePage(type, params) {
  return axios({
    url: `itms/finance/${type}`,
    method: 'get',
    params
  })
}

export function getItmsBalancePage(type, params) {
  return axios({
    url: `/itms/balance/${type}`,
    method: 'post',
    data: params
  })
}

export function getItmsBalanceFunc(type, params) {
  return axios({
    url: `/itms/balance/${type}`,
    method: 'post',
    data: params
  })
}

export function getItmsPayBillFunc(type, params) {
  return axios({
    url: `/itms/payment/${type}`,
    method: 'post',
    data: params
  })
}

export function updateItmsPayBill(type, params) {
  return axios({
    url: `/itms/payment/${type}`,
    method: 'put',
    data: params
  })
}

export function delItmsPayBill(type, params) {
  return axios({
    url: `/itms/payment/${type}`,
    method: 'delete',
    data: params
  })
}

export function getItmsPayBillById(type, params) {
  return axios({
    url: `/itms/payment/${type}`,
    method: 'get',
    params: params
  })
}

export function updateItmsBalance(type, params) {
  return axios({
    url: `/itms/balance/${type}`,
    method: 'put',
    data: params
  })
}

export function delItmsBalance(type, params) {
  return axios({
    url: `/itms/balance/${type}`,
    method: 'delete',
    data: params
  })
}

export function getItmsBalancePageById(type, params) {
  return axios({
    url: `/itms/balance/${type}`,
    method: 'get',
    params: params
  })
}

export function getItmsChargeFinancePage(params) {
  return axios({
    url: `itms/finance/charge_list`,
    method: 'post',
    data: params
  })
}

export function getItmsPayFinancePage(params) {
  return axios({
    url: `itms/finance/pay_list`,
    method: 'post',
    data: params
  })
}

export function ItmsFinanceFunc(type, data) {
  return axios({
    url: `itms/finance/${type}`,
    method: 'post',
    data
  })
}

export function exportItmsFinance(type, parameter) {
  return axios({
    url: `itms/finance/${type}`,
    method: 'get',
    params: parameter
  })
}

export function getItmsPayQrcode(data) {
  return axios({
    url: `itms/finance/qrcode`,
    method: 'post',
    data
  })
}

export function exportItmsOrderData(parameter) {
  return axios({
    url: `itms/order/export_data`,
    method: 'get',
    params: parameter
  })
}

export function exportItmsStatics(type, parameter) {
  return axios({
    url: `itms/statics/${type}`,
    method: 'get',
    params: parameter
  })
}

export function createItmsBalance(data) {
  return axios({
    url: `itms/balance/create_itms_balance`,
    method: 'post',
    data
  })
}

export function createItmsPayment(data) {
  return axios({
    url: `itms/payment/create_itms_payment`,
    method: 'post',
    data
  })
}

export function getBalanceDetailByOrderId(data) {
  return axios({
    url: `itms/finance/getBalanceInfoByOrderId/${data}`,
    method: 'get'
  })
}

export function getPaymentDetailByOrderId(data) {
  return axios({
    url: `itms/finance/getPaymentInfoByOrderId/${data}`,
    method: 'get'
  })
}

export function saveItmsOffice(data) {
  return axios({
    url: `itms/order/save_office`,
    method: `post`,
    data
  })
}

export function getItmsOfficePage(data) {
  return axios({
    url: `itms/order/list_office`,
    method: `post`,
    data
  })
}

// 审核结算单
export function batchReviewedBalance(data) {
  return axios({
    url: '/itms/balance/batch_reviewed',
    method: 'post',
    data
  })
}

// 反审核结算单
export function batchReverReviewedBalance(data) {
  return axios({
    url: '/itms/balance/batch_rever_reviewed',
    method: 'post',
    data
  })
}

// 审核付款单
export function batchReviewedPayment(data) {
  return axios({
    url: '/itms/payment/batch_reviewed',
    method: 'post',
    data
  })
}

// 反审核付款单
export function batchReverReviewedPayment(data) {
  return axios({
    url: '/itms/payment/batch_rever_reviewed',
    method: 'post',
    data
  })
}

// 导出国贸结算单
export function exportItmsBalanceSheet(data) {
  return axios({
    url: '/itms/balance/exportItmsBalanceSheet',
    method: 'post',
    data
  })
}

// 导出国贸付款单
export function exportItmsPaymentSheet(data) {
  return axios({
    url: '/itms/payment/exportItmsPaymentSheet',
    method: 'post',
    data
  })
}

// 分页查询国贸拖运业务
export function pageItmsConOrderList(data) {
  return axios({
    url: `/itms/con_order/pageItmsConOrderList`,
    method: 'post',
    data
  })
}

// 根据 id 查询单个拖运订单详情信息
export function getItmsConOrderById(id) {
  return axios({
    url: `/itms/con_order/getItmsConOrderById/${id}`,
    method: 'get'
  })
}

// 批量新增国贸拖运信息
export function saveBatchItmsConOrder(data) {
  return axios({
    url: `/itms/con_order/saveBatchItmsConOrder`,
    method: 'post',
    data
  })
}

// 修改国贸拖运信息
export function updateItmsConOrder(data) {
  return axios({
    url: `/itms/con_order/updateItmsConOrder`,
    method: 'post',
    data
  })
}

// 删除国贸拖运信息
export function deleteItmsConOrder(data) {
  return axios({
    url: `/itms/con_order/deleteItmsConOrder`,
    method: 'post',
    data
  })
}

export function getAreaInit () {
  return axios({
    url: `/itms/point/init_area`,
    method: 'get'
  })
}

export function listPointInfos(data) {
  return axios({
    url: '/itms/point/query',
    method: 'post',
    data
  })
}

export function saveOrModifyPoint(data) {
  return axios({
    url: '/itms/point/saveAndModify',
    method: 'post',
    data
  })
}

export function deletePoint(data) {
  return axios({
    url: '/itms/point/deletePoint',
    method: 'post',
    data
  })
}

export function getPointInit () {
  return axios({
    url: `/itms/point/init_point`,
    method: 'get'
  })
}

export function listPriceInfos(data) {
  return axios({
    url: '/itms/price/query',
    method: 'post',
    data
  })
}

export function saveOrModifyPrice(data) {
  return axios({
    url: '/itms/price/saveAndModify',
    method: 'post',
    data
  })
}

export function deletePrice(data) {
  return axios({
    url: '/itms/price/deletePrice',
    method: 'post',
    data
  })
}

// 国贸拖运业务匹配区域价格
export function matchConPrice(data) {
  return axios({
    url: `/itms/con_order/matchConPrice`,
    method: 'post',
    data
  })
}

// 拖运状态完成
export function confirmConShipmentStatus(data) {
  return axios({
    url: `/itms/con_order/confirmConShipmentStatus`,
    method: 'post',
    data
  })
}

// 拖运状态取消
export function cancelConShipmentStatus(data) {
  return axios({
    url: `/itms/con_order/cancelConShipmentStatus`,
    method: 'post',
    data
  })
}

export function listAllItmsOrderConShipment(data) {
  return axios({
    url: `/itms/con_order/listAllItmsOrderConShipment`,
    method: 'post',
    data
  })
}

export function listAllStatisticsItmsOrderConShipment(data) {
  return axios({
    url: `/itms/con_order/listAllStatistics`,
    method: 'post',
    data
  })
}

export function listSupplierPayStatisticsItmsOrderConShipment(data) {
  return axios({
    url: `/itms/con_order/listSupplierPayStatistics`,
    method: 'post',
    data
  })
}

export function listSupplierBoxTeuStatisticsItmsOrderConShipment(data) {
  return axios({
    url: `/itms/con_order/listSupplierBoxTeuStatistics`,
    method: 'post',
    data
  })
}

export function queryItmsOrderConTenDayNotChargeOrPayOrderList(data) {
  return axios({
    url: `/itms/order/queryTenDayNotChargeOrPayOrderList`,
    method: 'post',
    data
  })
}

export function exportItmsOrderConShipmentSheet(data) {
  return axios({
    url: `/itms/con_order/exportSheet`,
    method: 'post',
    data
  })
}

export function queryItmsOrderConWeekAgoNoFinishOrderList() {
  return axios({
    url: `/itms/con_order/queryWeekAgoNoFinishOrderList`,
    method: 'post'
  })
}

export function existItmsOrderEpNo(data) {
  return axios({
    url: '/itms/con_order/existEpNo',
    method: 'post',
    data
  })
}

export function existItmsOrderJOBNo(data) {
  return axios({
    url: '/itms/con_order/existJOBNo',
    method: 'post',
    data
  })
}

export function existItmsOrderBusinessNo(data) {
  return axios({
    url: '/itms/con_order/existBusinessNo',
    method: 'post',
    data
  })
}

export function initItmsConCustomerInfo() {
  return axios({
    url: `/itms/con_order/initCustomerInfo`,
    method: 'get'
  })
}

export function exportYearBoxTotalDetailSheet(data) {
  return axios({
    url: '/itms/con_order/exportYearBoxTotalDetailSheet',
    method: 'post',
    data
  })
}

export function exportSupplierBusinessTypeTotalSheet(data) {
  return axios({
    url: '/itms/con_order/exportSupplierBusinessTypeTotalSheet',
    method: 'post',
    data
  })
}

export function copyItmsOrder(data) {
  return axios({
    url: '/itms/con_order/copyItmsOrder',
    method: 'post',
    data
  })
}

export function listCustomerProfit(data) {
  return axios({
    url: 'itms/statics/listCustomerProfit',
    method: 'post',
    data
  })
}

export function exportCustomerProfitSheet(data) {
  return axios({
    url: 'itms/statics/exportCustomerProfitSheet',
    method: 'post',
    data
  })
}

export function statisticsTop20AuthAgencyData(data) {
  return axios({
    url: `itms/statics/statisticsTop20AuthAgencyData`,
    method: 'post',
    data
  })
}

export function handleStatisticsByBusinessType(data) {
  return axios({
    url: `itms/statics/statisticsByBusinessType`,
    method: 'post',
    data
  })
}

export function handleUntaxChargeStatisticsByBusinessType(data) {
  return axios({
    url: `itms/statics/statisticsUnTaxChargeByBusinessType`,
    method: 'post',
    data
  })
}

export function handleUntaxPayStatisticsByBusinessType(data) {
  return axios({
    url: `itms/statics/statisticsUnTaxPayByBusinessType`,
    method: 'post',
    data
  })
}

export function statisticsAreaLittleBoxNum(data) {
  return axios({
    url: `/itms/con_order/statisticsAreaLittleBoxNum`,
    method: 'post',
    data
  })
}

export function statisticsAreaLargeBoxNum(data) {
  return axios({
    url: `/itms/con_order/statisticsAreaLargeBoxNum`,
    method: 'post',
    data
  })
}

export function statisticsAreaTeuNum(data) {
  return axios({
    url: `/itms/con_order/statisticsAreaTeuNum`,
    method: 'post',
    data
  })
}

export function downloadConShipmentTemplate() {
  return axios({
    url: `/itms/con_order/downloadTemplate`,
    method: 'get'
  })
}

export function importTemplateAddOrder(data) {
  return axios({
    url: `/itms/con_order/newImportTemplateAddOrder`,
    method: 'post',
    data
  })
}

export function statisticsContainerTeuNum(data) {
  return axios({
    url: `/itms/con_order/statisticsContainerTypeTeuNum`,
    method: 'post',
    data
  })
}

export function statisticsRouteContainerTeuNum(data) {
  return axios({
    url: `/itms/con_order/statisticsRouteContainerTeuNum`,
    method: 'post',
    data
  })
}

export function uploadFileByOrder(data) {
  return axios({
    url: `itms/order/uploadOrderFile`,
    method: 'post',
    data
  })
}

export function deleteOrderFile(params) {
  return axios({
    url: `itms/order/deleteOrderFile`,
    method: 'get',
    params
  })
}

export function exportBalanceInvoices(data) {
  return axios({
    url: `itms/balance/export_invoice`,
    method: 'post',
    data
  })
}

export function getDetailByQuotaNum(quotaNum) {
  return axios({
    url: `itms/order/detailByQuotaNum`,
    method: 'get',
    params: quotaNum
  })
}

export function saveAdjustOrder(data) {
  return axios({
    url: `itms/adjust-order/saveAdjust`,
    method: 'post',
    data
  })
}

export function initItmsAdjust() {
  return axios({
    url: `itms/adjust-order/init`,
    method: 'get'
  })
}

export function pageListAdjust(data) {
  return axios({
    url: `itms/adjust-order/pageList`,
    method: 'post',
    data
  })
}

export function submitApplyAdjust(data) {
  return axios({
    url: `itms/adjust-order/submitApply`,
    method: 'post',
    data
  })
}

export function deleteAdjustOrder(data) {
  return axios({
    url: `itms/adjust-order/deleteAdjustOrder`,
    method: 'post',
    data
  })
}
